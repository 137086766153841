// Create own spacer map
$spacer: 1rem;
$custom-spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  "5-px": 5px,
  "10-px": 10px,
  "20-px": 20px,
  "30-px": 30px,
  "35-px": 35px,
  "40-px": 40px,
  "45-px": 45px,
  "50-px": 50px,
  "60-px": 60px,
  "70-px": 70px,
  "80-px": 80px,
  "90-px": 90px,
  "100-px": 100px,
  "120-px": 120px,
  "125-px": 125px,
  "130-px": 130px,
  "150-px": 150px,
  "200-px": 200px,
  "250-px": 250px,
  "300-px": 300px,
  "350-px": 350px,
  "400-px": 400px
);

// Merge the maps
$spacers: map-merge($spacers, $custom-spacers);