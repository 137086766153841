@use "./variables.scss" as var;
/** bootstrap custom config ****
/*
    color => ~/src/assets/scss/bootstrap/color.scss
    margin,padding and spacing => ~/src/assets/scss/bootstrap/spacer.scss
    width and height => ~/src/assets/scss/bootstrap/width_height.scss
*/

/** Generate custom font sizes */
/**
    Example Usage (you can use within 1px to 50 px)
    eg. fs-1-px, fs-20-px ...... fs-50-px
*/
@for $i from 1 through 50 {
  .fs-#{$i}-px {
    font-size: 1px * $i;
  }
}

/** End generate custom font sizes */

//write your class

%unset-input {
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  // -webkit-appearance: none;
}

/** font family */

//notosan

@font-face {
  font-family: "NotoSansJP";
  src: url("../fonts/notosansjp/NotoSansJP-Regular.otf");
}

@font-face {
  font-family: "NotoSansJP-Medium";
  src: url("../fonts/notosansjp/NotoSansJP-Medium.otf");
}

.font-notosans-jp {
  font-family: "NotoSansJP";
}

.font-notosans-jp-medium {
  font-family: "NotoSansJP-Medium";
}

//end notosan

@font-face {
  font-family: "Ab-j_choki";
  src: url("../fonts/Ab-j_choki.ttf") format("truetype");
}

.font-abj-choki {
  font-family: "Ab-j_choki";
}

@font-face {
  font-family: "Ethno";
  src: url("../fonts/ethnocentric/Ethnocentric.otf");
}

.font-ethno {
  font-family: "Ethno";
  // font-weight: ;
}

// @font-face {
//   font-family: "ethno-light";
//   src: url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("woff2"),
//     url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("woff"),
//     url("https://use.typekit.net/af/5868d5/000000000000000077359667/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
//       format("opentype");
//   font-display: auto;
//   font-style: normal;
//   font-weight: 300;
//   font-stretch: normal;
// }

@font-face {
  font-family: "ethnocentric";
  src: url("https://use.typekit.net/af/03258e/000000000000000077359660/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/03258e/000000000000000077359660/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/03258e/000000000000000077359660/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

.font-ethno-normal {
  font-family: "ethnocentric";
  font-weight: 900;
}

@font-face {
  font-family: "ethno-light";
  src: url("../fonts/ethnocentric/Ethnocentric\ Light.otf");
}
.font-ethno-light {
  font-family: "ethno-light";
  font-weight: 300;
}

.font-ethno-bold {
  font-family: "ethno-light";
  font-weight: 900;
}

@font-face {
  font-family: "Europa";
  src: url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot");
  src: url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/23c0fcab84d99da0de762de7e220a6e1.svg#Europa")
      format("svg");
}

// @font-face {
//   font-family: "Europa";
//   src: url("../fonts/europa.ttf");
// }
.font-europa-regular {
  font-family: "Europa";
}

/** end font family */

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

/** Menu Button Classes */
.menu-button {
  --path: 90% 10%, 100% 35%, 100% 70%, 90% 90%, 10% 90%, 0% 70%, 0% 35%, 10% 10%;
  --left-path: polygon(
    79% 9%,
    99% 10%,
    19% 33%,
    18% 69%,
    98% 91%,
    79% 92%,
    0% 69%,
    -1% 32%
  );

  width: 200px;

  .outside {
    position: relative;
    width: 173px;
    height: 55px;
    background: var.$primary-color;
    clip-path: polygon(var(--path));
  }

  .inside {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: var.$dark-color;
    clip-path: polygon(var(--path));
  }

  .left {
    width: 20px;
    height: 55px;
    margin-right: -12px;
    background: var.$primary-color;
    clip-path: var(--left-path);
  }

  .right {
    width: 20px;
    height: 55px;
    margin-left: -12px;
    background: var.$primary-color;
    clip-path: var(--left-path);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .text {
    color: white;
  }
}

.menu-button:hover {
  .outside {
    background: var.$secondary-color;
    cursor: pointer;
  }

  .left {
    background: var.$secondary-color;
  }

  .right {
    background: var.$secondary-color;
  }

  .text {
    color: var.$secondary-color;
  }
}

/** End Menu Button Classes */

/** content */
@mixin content(
  $cb-width: 2px,
  //content-border-width
  $border-radius: 12px,

  //corner radius
  /* title space */ $title-width: 267px,

  //317
  $title-height: 45px,
  $title-slop: 46px,
  $title-slope-adjust-point: 1px,
  /* end title space */ /*bottom space */ $bottom-space-width: 340px,
  $bottom-space-height: 40px,
  $bottom-space-slope: 25px,
  $bottom-left-width: calc((100% - $bottom-space-width) / 2)
) {
  /* clip path variables */

  // $cb-width: 2px; //content-border-width
  // $border-radius: 12px; //corner radius

  // /* title space */
  // $title-width: 267px; //317

  // $title-height: 50px;
  // $title-slop: 50px;
  // $title-slope-adjust-point: 1px;
  // /* end title space */

  // /*bottom space */
  // $bottom-space-width: 340px;
  // $bottom-space-height: 40px;
  // $bottom-space-slope: 25px;
  // $bottom-left-width: calc((100% - $bottom-space-width) / 2);

  .content-bg-darker {
    background-color: rgba(var.$dark-color, 0.2);
  }

  /* end clip path variables */
  .content-border {
    background-color: rgba(var.$primary-color, 0.5);
    min-height: 200px;
    width: 100%;
    height: 100%;
    z-index: -1;

    /* Content Border Path */
    clip-path: polygon(
      evenodd,
      /**
        //origin clip path
        clip-path: polygon(evenodd, 0% 0%, 21.26% 0%, 32.24% 13.98%, 95.63% 13.98%, 100% 28.27%, 100% 89.74%, 95.63% 100%, 62.63% 100%, 53.9% 92.23%, 39.51% 92.3%, 32.24% 100%, 4% 100%, 7.8% 94.72%, 3.76% 84.99%, 3.9% 6.5%, 19.88% 6.5%, 30.02% 20.22%, 92.74% 20.22%, 95.75% 28.27%, 95.83% 88.04%, 92.74% 93.72%, 62.49% 94.72%, 54.62% 86.02%, 39.32% 85.71%, 32.24% 94.72%, 7.8% 94.72%, 4% 100%, 0% 88.99%);
        background-color: #6beb47;

        */
        /*Outer Path */ 0% 0%,
      /* 1 outer left start point*/ $title-width 0%,
      /* 2 outer (Title Width) */
        calc($title-width + $title-slop - $title-slope-adjust-point)
        $title-height + $title-slope-adjust-point,
      /* 3 outer Title Height */ calc(100% - $border-radius) $title-height,
      /* 4 outer right top width to end*/ 100%
        calc($title-height + $border-radius),
      /* 5 outer right top height*/ 100% calc(100% - $border-radius),
      /* 6 outer bottom height*/ calc(100% - $border-radius) 100%,
      /* 7 outer bottom width*/ calc(100% - $bottom-left-width) 100%,
      /* 8 outer bottom space*/
        calc(100% - $bottom-left-width - $bottom-space-slope - $cb-width)
        calc(100% - $bottom-space-height),
      /* 9 outer bottom space*/
        calc($bottom-left-width + $bottom-space-slope + $cb-width)
        calc(100% - $bottom-space-height),
      /* 10 outer bottom space*/ calc((100% - $bottom-space-width) / 2) 100%,
      /* 11 outer bottom space*/ $border-radius 100%,
      /* outer junction point */ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ calc($cb-width)
        calc(100% - $border-radius - $cb-width),
      /* end point */ /*Inner Path*/ $cb-width $cb-width,
      /* 1 inner left start point*/
        ($title-width - $cb-width + $title-slope-adjust-point) $cb-width,
      /* 2 inner (300px,Title Width */
        (calc($title-width + $title-slop) - $cb-width)
        ($title-height + $cb-width),
      /* 3 inner Title Height */ calc((100% - $cb-width) - $border-radius)
        ($title-height + $cb-width),
      /* 4 inner right top width to end*/ calc(100% - $cb-width)
        calc(($title-height + $cb-width) + $border-radius),
      /* 5 inner right top height*/ calc(100% - $cb-width)
        calc((100% - $border-radius) - $cb-width),
      /* 6 inner bottom height*/ calc(100% - $border-radius - $cb-width)
        calc(100% - $cb-width),
      /* 7 inner bottom width*/ calc(100% - $bottom-left-width + $cb-width)
        calc(100% - $cb-width),
      /* 8 inner bottom space*/
        calc(100% - $bottom-left-width - $bottom-space-slope)
        calc(100% - calc($bottom-space-height + $cb-width)),
      /* 9 inner bottom space*/ calc($bottom-left-width + $bottom-space-slope)
        calc(100% - calc($bottom-space-height + $cb-width)),
      /* 10 inner bottom space*/ calc($bottom-left-width - $cb-width)
        calc(100% - $cb-width),
      /* 11 inner bottom space*/ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ $border-radius 100%,
      /* outer junction point */ 0% calc(100% - $border-radius) /* end point */
    );
    /*End Content Border Path */
  }
  .content-border-none-bt-space {
    background-color: rgba(var.$primary-color, 0.5);
    min-height: 200px;
    width: 100%;
    height: calc(100% + 3px);
    z-index: -1;

    /* Content Border Path */
    clip-path: polygon(
      evenodd,
      /**
        //origin clip path
        clip-path: polygon(evenodd, 0% 0%, 21.26% 0%, 32.24% 13.98%, 95.63% 13.98%, 100% 28.27%, 100% 89.74%, 95.63% 100%, 62.63% 100%, 53.9% 92.23%, 39.51% 92.3%, 32.24% 100%, 4% 100%, 7.8% 94.72%, 3.76% 84.99%, 3.9% 6.5%, 19.88% 6.5%, 30.02% 20.22%, 92.74% 20.22%, 95.75% 28.27%, 95.83% 88.04%, 92.74% 93.72%, 62.49% 94.72%, 54.62% 86.02%, 39.32% 85.71%, 32.24% 94.72%, 7.8% 94.72%, 4% 100%, 0% 88.99%);
        background-color: #6beb47;

        */
        /*Outer Path */ 0% 0%,
      /* 1 outer left start point*/ $title-width 0%,
      /* 2 outer (Title Width) */
        calc($title-width + $title-slop - $title-slope-adjust-point)
        $title-height + $title-slope-adjust-point,
      /* 3 outer Title Height */ calc(100% - $border-radius) $title-height,
      /* 4 outer right top width to end*/ 100%
        calc($title-height + $border-radius),
      /* 5 outer right top height*/ 100% calc(100% - $border-radius),
      /* 6 outer bottom height*/ calc(100% - $border-radius) 100%,
      /* 7 outer bottom width*/ $border-radius 100%,
      /* outer junction point */ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ calc($cb-width)
        calc(100% - $border-radius - $cb-width),
      /* end point */ /*Inner Path*/ $cb-width $cb-width,
      /* 1 inner left start point*/
        ($title-width - $cb-width + $title-slope-adjust-point) $cb-width,
      /* 2 inner (300px,Title Width */
        (calc($title-width + $title-slop) - $cb-width)
        ($title-height + $cb-width),
      /* 3 inner Title Height */ calc((100% - $cb-width) - $border-radius)
        ($title-height + $cb-width),
      /* 4 inner right top width to end*/ calc(100% - $cb-width)
        calc(($title-height + $cb-width) + $border-radius),
      /* 5 inner right top height*/ calc(100% - $cb-width)
        calc((100% - $border-radius) - $cb-width),
      /* 6 inner bottom height*/ calc(100% - $border-radius - $cb-width)
        calc(100% - $cb-width),
      /* 7 inner bottom width*/ calc($border-radius + $cb-width)
        calc(100% - $cb-width),
      /* inner junction point */ $border-radius 100%,
      /* outer junction point */ 0% calc(100% - $border-radius) /* end point */
    );
    /*End Content Border Path */
  }

  .title {
    $font-size: 30px;

    .font-size {
      font-size: $font-size;
    }

    width: $title-width;
    font-size: $font-size;
    color: white;
    // font-weight: 200;
    font-family: "Ab-j_choki";
    // background-color: red;
    // margin-left: 5px;
    // margin-top: 3px;
    // background-color: rgba(var.$dark-color, 0.3);
  }

  .wide-top-space {
    width: calc(100% - 160px) !important;
    height: $title-height;
  }
  .top-space {
    width: calc(100% - $title-width);
    height: $title-height;
  }
  .body {
    // background-color: rgba(var.$dark-color, 0.3);

    .header-font {
      font-size: 20px;
      text-align: center;
      // font-weight: 800;
      font-family: "NotoSansJP";
    }

    .body-font {
      font-size: 18px;
      font-family: "NotoSansJP";
      line-height: 22px;
    }

    .publish-icon {
      width: 28px;
      height: 9px;
      background-image: url("../icons/publish.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      margin-top: 4px;
      position: relative;

      .publish-up-arrow {
        transition: all 0.5s ease;
      }

      // background-color: red;
      &:hover .publish-up-arrow1 {
        margin-top: -18px;
      }

      &:hover .publish-up-arrow2 {
        margin-top: -10px;
      }
    }

    %publish-up-arrow {
      width: 14px;
      height: 8px;
      background-image: url("../icons/publish_up_arrow.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      left: 7px;
      top: 1px;
    }

    .publish-up-arrow1 {
      @extend %publish-up-arrow;
    }

    .publish-up-arrow2 {
      @extend %publish-up-arrow;
    }

    .edit-icon {
      width: 38px;
      height: 32px;
      margin-top: 2px;
      position: relative;
      background-image: url("../icons/edit.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        margin-top: 2px;
        background-image: url("../icons/settings_icon_hover.svg");
      }
    }

    .pen-icon {
      width: 50px;
      height: 40px;
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        .PenToSquareSolid1 {
          transition: 0.3s;
          transform: translate(7px, -62%);
        }
      }
    }

    .detail-more-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/detail_more.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      &:hover {
        transition-delay: 0.2s;
        background-image: url("../icons/detail_more_hover.svg");
      }
    }

    .delete-icon {
      display: inline-block;
      width: 30px;
      height: 40px;
      margin-top: 3px;
      //background-image: url("../icons/delete.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      &:hover {
        .trash-can-solid-2 {
          margin-top: 3px;
          transition: 0.3s;
          transform: matrix(0.829, -0.545, 0.545, 0.829, -1, 0.878);
        }
      }
      // background-color: red;
    }

    .arrow-icon {
      width: 16px;
      height: 18px;
      background-image: url("../icons/arrow.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .uncheck-icon {
      width: 25px;
      height: 25px;
      background-image: url("../icons/uncheck.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .check-icon {
      width: 25px;
      height: 25px;
      background-image: url("../icons/check.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .dropdown-icon {
      width: 16px;
      height: 16px;
      background-image: url("../icons/dropdown.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .copy-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/copy.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .plus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .minus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/minus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      rotate: 45deg;
    }
    .grey-minus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/grey_minus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .pink-plus-icon {
      width: 20px;
      height: 20px;
      background-image: url("../icons/pink-plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      // background-color: red;
    }

    .random-plus {
      width: 30px;
      height: 30px;
      background-image: url("../icons/random-plus.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
      &:hover {
        background-image: url("../icons/random-plus-hover.svg");
      }
    }
    .random-plus-disable {
      width: 30px;
      height: 30px;
      background-image: url("../icons/random-plus-disable.svg");
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 1;
    }
  }

  .bottom {
    .space-width {
      width: $bottom-space-width;
    }

    .font-size {
      font-size: 25px;
    }

    .left-arrow {
      width: 50px;
      height: 15px;
      background-image: url("../icons/left-arrow.svg");
      background-repeat: no-repeat;
      margin-top: 27px;
      cursor: pointer;
      // background-color: red;
    }

    .left-arrow:hover {
      background-image: url("../icons/left-arrow-filled.svg");
    }

    .right-arrow {
      width: 50px;
      height: 15px;
      background-image: url("../icons/left-arrow.svg");
      background-repeat: no-repeat;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      margin-top: 27px;
      cursor: pointer;
      // background-color: red;
    }

    .right-arrow:hover {
      background-image: url("../icons/left-arrow-filled.svg");
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.content {
  @include content();
}

.content-wide-title {
  @include content($title-width: 320px);
}
.content-wider-title {
  @include content($title-width: 380px);
}

/** end content */
.login-box {
  width: 910px;
  height: 510px;
  background-color: var.$dark-color;
  border: 1px solid rgba(36, 184, 192, 0.5);
  margin: auto;

  // @media (max-width: 1024px) {
  //     height: 100%;
  //     width: 85%;
  //     margin-bottom: auto;
  // }
}

/** Input Classes */
.activate-on-hover:hover {
  opacity: 1;

  .text-box {
    opacity: 1;
  }
}

.hide-placeholder:focus::placeholder {
  color: transparent !important;
}

.smaller-placeholder::placeholder {
  font-size: 15px !important;
  color: rgba(white, 0.6) !important;
}
.lighter-placeholder::placeholder {
  color: rgba(white, 0.4) !important;
}

.center-placeholder {
  padding-bottom: 0.5% !important;
}
.center-placeholder::placeholder {
  text-align: center !important;
}

// These classes were written because I do not know how to set minus margin with
// bootstrap clases, please forgive me for sinning.
.font-18px-impt {
  font-size: 18px !important;
}
.minus-margin {
  margin-top: -7px;
}
// text box
@mixin text-input($radius: 12px, $border-width: 1.5px) {
  $input-path: calc(100% - $radius) 100%, 100% 75%, 100% 25%,
    calc(100% - $radius) 0%, $radius 0%, 0% 25%, 0% 75%, $radius 100%;
  // all: unset;
  position: relative;
  min-width: 100px;
  min-height: 30px;

  &:focus-within {
    opacity: 1;
    .text-box {
      background-color: rgba(var.$primary-color, 1);
    }
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .text-box {
    position: absolute;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: rgba(var.$primary-color, 0.8);
    clip-path: polygon($input-path);

    &::after {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: $border-width;
    }
  }

  .error-border {
    background-color: rgba(var.$danger-color, 0.4); //border opacity
  }
  .inactive {
    background-color: rgba(#7a7373, 0.5);
  }
  .input {
    position: absolute;
    color: white;
    // font-size: 20px;
  }

  %error {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var.$danger-color;
    position: absolute;
    right: 0;
    bottom: -22px;
    font-family: "NotoSansJP";
  }

  .error {
    @extend %error;
  }

  .error-without-right {
    @extend %error;
    right: unset;
    bottom: -25px;
  }

  .error-left-align {
    @extend %error;
    left: 0;
    overflow: visible;
  }

  input {
    // all: unset;
    @extend %unset-input;
    caret-color: var.$primary-color;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding: 0 16px 0;
    // background-color: antiquewhite;
    // font-size: 20px;
    // text-align: center;
    &::placeholder {
      text-align: center;
      font-size: 15px;
      color: #7a7373;
      // height: 100%;
      // line-height: normal;
    }
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-input {
  @include text-input();
}
.text-input-lesser-radius {
  @include text-input($radius: 10px);
}
.text-input-bigger-border {
  @include text-input($radius: 10px, $border-width: 2.5px);
}
.text-input-big-border {
  @include text-input($radius: 16px, $border-width: 2px);
}
.text-input-lesser-opacity {
  @include text-input();

  opacity: 0.6;
  input {
    caret-color: rgba(var.$primary-color, 0.6);
  }
}
.text-input-white-caret {
  @include text-input();

  input {
    caret-color: white;
  }
}
//end text box

//Login Input
.login-input {
  $radius: 16px;
  $border-width: 1px;
  $input-path: calc(100% - $radius) 100%, 100% 75%, 100% 25%,
    calc(100% - $radius) 0%, $radius 0%, 0% 25%, 0% 75%, $radius 100%;

  $button-radius: 16px;
  $wrapper-border-width: 3.5px;
  $left-wrapper: calc($button-radius + $wrapper-border-width) 100%,
    $wrapper-border-width 75%, $wrapper-border-width 25%,
    calc($button-radius + $wrapper-border-width) 0%, $button-radius 0%, 0% 22%,
    0% 78%, $button-radius 100%;
  // all: unset;
  position: relative;
  min-width: 100px;
  min-height: 30px;
  opacity: 0.6;

  &:focus-within {
    opacity: 1;
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .left-wrapper {
    position: absolute;
    width: 25px;
    height: 55px;
    clip-path: polygon($left-wrapper);
    background-color: var.$primary-color;
    margin-left: -6px;
  }

  .text-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(var.$primary-color, 0.5);
    clip-path: polygon($input-path);

    &::after {
      content: "";
      position: absolute;
      background-color: var.$dark-color;
      clip-path: polygon($input-path);
      inset: $border-width;
    }
  }

  .right-wrapper {
    position: absolute;
    width: 25px;
    height: 55px;
    clip-path: polygon($left-wrapper);
    background-color: var.$primary-color;
    margin-left: 95%;
    transform: scaleX(-1);
  }

  .input {
    position: absolute;
    color: white;
  }

  input {
    // all: unset;
    @extend %unset-input;

    height: 100%;
    width: 100%;
    z-index: 1;
    // padding: 0 70px 0;
    padding-left: 50px;
    // text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
}

//End Login input

//input icon
.icon {
  background-image: url(../images/user-solid.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20%;
  padding: 10px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.icon-lock {
  background-image: url(../images/lock-solid.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20%;
  padding: 13px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.input-row {
  position: relative;
}

//end input icon

// search input box
.search-input {
  $radius: 10px;
  $slope: 40px;
  $inner-start-point: 3px;
  opacity: 0.7;

  .search-box {
    clip-path: polygon(
      calc(100% - $radius) 100%,
      100% calc(100% - $radius),
      100% $radius,
      calc(100% - $radius) 0%,
      0% 0%,
      $slope 100%
    );
    background-color: var.$primary-color;
    min-width: 100px;
    min-height: 30px;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &:focus-within {
    opacity: 1;
    .search-box {
      opacity: 1;
    }
  }

  &:has(input:valid) {
    opacity: 1;
  }

  .search-box::after {
    content: "";
    position: absolute;
    inset: 2px;
    clip-path: polygon(
      calc(100% - $radius) 100%,
      100% calc(100% - $radius),
      100% $radius,
      calc(100% - $radius) 0%,
      $inner-start-point 0%,
      $slope 100%
    );
    background-color: var.$dark-color;
  }

  .input {
    height: 95%;
    position: absolute;
    color: white;
    caret-color: rgba(var.$primary-color, 1);
  }

  .input::placeholder {
    color: white;
  }

  input {
    all: unset;
    height: 100%;
    width: 100%;
    padding-left: 48px;
    z-index: 1;
  }
}

// end search input box

/** End Input Classes */

/** Button Classes */

//base button
@mixin button($font-family: "Ab-j_choki", $font-size: 18px) {
  $border-width: 1px;
  $button-radius: 12px;
  $button-path: calc(100% - $button-radius) 100%, 100% 75%, 100% 25%,
    calc(100% - $button-radius) 0%, $button-radius 0%, 0% 25%, 0% 75%,
    $button-radius 100%;

  $wrapper-border-width: 4px;
  $button-wrapper-path: calc($button-radius + $wrapper-border-width) 100%,
    $wrapper-border-width 75%, $wrapper-border-width 25%,
    calc($button-radius + $wrapper-border-width) 0%, $button-radius 0%, 0% 22%,
    0% 78%, $button-radius 100%;

  position: relative;
  min-width: 100px;
  min-height: 20px;

  // width: 100%;
  // height: 100%;

  cursor: pointer;
  // background-color: red;

  .left-wrapper {
    position: absolute;
    clip-path: polygon($button-wrapper-path);
    background-color: var.$primary-color;
    inset: 1px;
  }

  .right-wrapper {
    position: absolute;
    clip-path: polygon($button-wrapper-path);
    background-color: var.$primary-color;
    inset: 1px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .inner {
    position: absolute;
    background-color: var.$primary-color;
    clip-path: polygon($button-path);
    inset: 1px;
    margin-left: 7px;
    margin-right: 7px;
    // left: 8px;
    // right: 8px;
  }

  .inner::after {
    content: "";
    position: absolute;
    background-color: var.$dark-color;
    clip-path: polygon($button-path);
    inset: $border-width;
  }

  .text {
    color: white;
    font-size: $font-size;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-family;
    width: 100%;
    text-align: center;
  }

  .box-button {
    color: white;
    font-size: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Ethno";
    width: 100%;
    text-align: center;
  }
}

%button {
  @include button();
}

//end base button

//primary button
.button-primary {
  @include button();

  .inner {
    background-color: rgba(var.$primary-color, 0.5);
  }

  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      background-color: var.$primary-color;
    }

    .text {
      color: var.$dark-color;
    }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end primary button

//grey (disabled?) button
.button-grey {
  @include button();

  .inner {
    background-color: rgba(#7a7373, 0.5);
  }
  .left-wrapper {
    background-color: #7a7373;
  }

  .right-wrapper {
    background-color: #7a7373;
  }
  .text {
    color: #7a7373;
  }
  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      background-color: var.$primary-color;
    }

    .text {
      color: var.$dark-color;
    }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end grey button

//primary button
.button-dialog {
  @include button($font-family: "ethno-light", $font-size: 15px);
  .text {
    margin-top: -3px;
  }

  .inner {
    background-color: rgba(var.$primary-color, 0.3);
  }

  &:hover {
    .inner {
      opacity: 1;
    }

    .inner::after {
      background-color: var.$primary-color;
    }

    .text {
      color: var.$dark-color;
    }

    .box-button {
      color: var.$dark-color;
    }
  }
}
//end primary button

//menu button
%button-menu-state {
  .left-wrapper {
    background-color: var.$secondary-color;
  }

  .inner {
    background-color: var.$secondary-color;
  }

  .inner::after {
    background-color: var.$dark-color;
  }

  .right-wrapper {
    background-color: var.$secondary-color;
  }

  .text {
    color: var.$secondary-color;
  }

  .box-button {
    color: var.$secondary-color;
  }
}

.button-menu {
  @extend %button;

  .inner {
    background-color: rgba(var.$primary-color, 0.3);
  }

  &:hover {
    @extend %button-menu-state;
  }
}

.selected-button-menu {
  @extend %button;
  @extend %button-menu-state;
}

//end menu button

/** End Button Classes */

//box
@mixin message-box($box-slope: 24px, $side-path-width: 38px) {
  position: relative;
  $slope: $box-slope;
  .outside-box {
    clip-path: polygon(
      $slope 0,
      calc(100% - $slope) 0,
      100% $slope,
      100% calc(100% - $slope),
      calc(100% - $slope) 100%,
      $slope 100%,
      0 calc(100% - $slope),
      0 $slope
    );
    position: absolute;
    // overflow: visible;
    width: 100%;
    height: 100%;
    background-color: rgba(var.$primary-color, 0.7);
    z-index: -1;
  }

  .middle-choose-group-right-box {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 27%;
    z-index: 0;
    top: 33.5%;
    right: 0;
    background-color: var.$dark-color;
  }

  .middle-choose-group-left-box {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 15%;
    z-index: 0;
    top: 46%;
    left: 0;
    background-color: var.$dark-color;
  }

  .middle-box {
    content: "";
    position: absolute;

    display: block;
    width: calc(100% + 2px);
    margin-left: -0.6px;
    height: 20%;
    z-index: 0;
    top: 38%;
    left: 0;
    right: 0;
    background-color: var.$dark-color;
    // background-color: red;
  }

  .inside-box {
    clip-path: polygon(
      $slope 0,
      calc(100% - $slope) 0,
      100% $slope,
      100% calc(100% - $slope),
      calc(100% - $slope) 100%,
      $slope 100%,
      0 calc(100% - $slope),
      0 $slope
    );
    content: "";
    position: absolute;
    display: block;
    inset: 3px;
    background-color: var.$dark-color;
  }

  .save-box {
    .body {
      // width: 100%;
      .container-box {
        width: 100%;
        height: 100%;
      }

      .button-row {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-top: 70px;
      }

      .con-button {
        width: 182px;
        height: 40px;
      }
    }

    .path {
      margin-top: 155px;
    }
  }

  .side-path {
    $border-width: 2px;
    $slope: 25px;
    clip-path: polygon(
      0% $border-width,
      0% 0%,
      calc(60% + $border-width) $slope,
      calc(60% + $border-width) 100%,
      60% 100%,
      60% calc($slope + $border-width)
    );
    background-color: var.$primary-color;
    height: 40%;
    width: $side-path-width;
    opacity: 0.7;
    // z-index: 0;
  }
}

.message-box {
  @include message-box();
}

.group-select-message-box {
  @include message-box($box-slope: 35px, $side-path-width: 50px);
}

//end box

//Login Button
.button-text {
  color: black;
  background-image: url("../images/btn-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.button-text:hover {
  color: var.$dark-color;
  background-image: url("../images/btn-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.link {
  text-decoration: none;
}

//End Login Button

.error-border {
  background-color: rgba(var.$danger-color, 0.4) !important; //border opacity
}

.image-box {
  z-index: 0;
  $border-width: 1px;
  $button-radius: 10px;
  $path: calc(100% - $button-radius) 100%, 100% 97.5%, 100% 2.5%,
    calc(100% - $button-radius) 0%, $button-radius 0%, 0% 2.5%, 0% 97.5%,
    $button-radius 100%;

  // clip-path: polygon($path);
  // background-color: var.$primary-color;
  min-width: 100px;
  min-height: 20px;

  position: relative;
  background-color: rgba(var.$primary-color, 0.5);
  clip-path: polygon($path);

  .dark-bg {
    // content: "";
    position: absolute;
    background-color: var.$dark-color;
    clip-path: polygon($path);
    // background-image: url("../images/input_name.png");
    inset: $border-width;
  }

  .upload-image {
    position: absolute;
    clip-path: polygon($path);
    inset: $border-width;
    z-index: 2;
    // inset: $border-width;
    // background-image: url("../images/input_name.png");
    // background-repeat: no-repeat;
    // background-size: cover;
  }
}

.cust-modal {
  .overlay {
    width: 100vw;
    height: 100vh;
    inset: 0;
    position: fixed;
    background-color: rgba(1, 1, 1, 0.852);
    z-index: 10;
  }

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

//loading modal

.loading-text {
  animation: loading forwards;
  animation-duration: 10s;
  // position: relative;
  animation-fill-mode: forwards;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.loading-complete-text {
  animation: loading_complete forwards;
  animation-duration: 10s;
  // position: relative;
  animation-fill-mode: forwards;
}

@keyframes loading_complete {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.app-text::after {
  content: "";
  animation: spin forwards;
  animation-duration: 10s;
  position: relative;
  animation-fill-mode: forwards;
}

@keyframes spin {
  0% {
    content: "ローディング. . . .";
    opacity: 1;
  }

  20% {
    content: "ローディング. . . .";
    opacity: 0.5;
  }

  40% {
    content: "ローディング. . . .";
    opacity: 0.1;
  }

  50% {
    content: "完了";
    opacity: 0.1;
  }

  70% {
    content: "完了";
    opacity: 0.7;
  }

  100% {
    content: "完了";
    opacity: 1;
  }
}

.re-text::after {
  content: "";
  animation: animate forwards;
  animation-duration: 10s;
  position: relative;
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    content: "発行中. . . . .";
    opacity: 1;
  }

  20% {
    content: "発行中. . . . .";
    opacity: 0.5;
  }

  40% {
    content: "発行中. . . . .";
    opacity: 0.1;
  }

  50% {
    content: "完了";
    opacity: 0.1;
  }

  70% {
    content: "完了";
    opacity: 0.7;
  }

  100% {
    content: "完了";
    opacity: 1;
  }
}

//Progress Bar
.loader-border {
  display: block;
  position: relative;
  border: 1px solid rgba(var.$primary-color, 0.8);
  border-radius: 10px;
  padding: 1px 1px 1px 1px;
}
.loader {
  display: block;
  position: relative;
  // border: 1px solid var.$primary-color;
  // border-radius: 10px;
  overflow: hidden;
  // animation-fill-mode: forwards;
  // padding: 2px 2px 2px 2px;

  .circle {
    width: 20px;
    height: 100%;
    background: var.$primary-color;
    // background-color: red;
    border-radius: 10px;
    // background-image: url(../icons/elements/progress_bar.svg);
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: 20%;
    // padding: 13px;
    position: absolute;
    // box-sizing: border-box;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  .progress {
    position: absolute;
    left: 1;
    top: 1;
    height: 100%;
    width: 0;
    border-radius: 10px;
    background: var.$primary-color;
    // animation: prog 10s forwards;
    // transition: width 2s;
  }
}

// .loader:after {
//   content: "";
//   position: absolute;
//   left: 1;
//   top: 1;
//   height: 100%;
//   width: 0;
//   border-radius: 10px;
//   background: var.$primary-color;
//   animation: prog 10s forwards;
// }

// @keyframes prog {
//   0% {
//     width: 5%;
//   }

//   100% {
//     width: 100%;
//   }
// }

//End Progress Bar

//Button Opacity
.opacity-button {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

//End Button Opacity

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//End loading modal

//blink animation
.blink {
  animation: blink 0.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

//End blink animation

//choose group
.choose-group-list {
  .name{
    width: 50%;
  }
  .groupno{
    width: 50%;
  }
  //transition: all 0.3s ease-in-out;
  font-family: "Europa";
  .choose-arrow {
    display: none;
  }
  &:hover {
    // background-color: red;
    .name{
      width: 40%;
    }
    .groupno{
      width: 40%;
      text-align: right;
    }
    padding: 0 20px 0;
  }

  &:hover .choose-arrow {
    display: block;
  }
}
//end choose group

// Login Mask Cropping
.crop {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.crop img {
  width: 430px;
  height: auto;
  margin: 15px 0 0 -60px;
}

.error-valid-login {
  font-family: "Europa";
  color: #ff0000;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}

.login-container {
  height: 96px;
}


.select-box {
  border-radius: 5px;
  background-color: white;
  width: 100px;
  // display: none;
  // height: 80px;
  z-index: 10;
  .select-row {
    color: black;
    &:hover {
      cursor: pointer;
      background-color: #7a7373;
    }
  }
}
.dynamic-grid-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, min-content);
  //grid-auto-flow: column;
}
.w-80{
  width: 80%;
}
.w-87{
  width: 87% !important;
}
.w-90{
  width: 90%;
}


.text-word-wrap {
  word-wrap: break-word;
}


.gird-table {
  table {
    width: 100%;
  }
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    overflow: hidden;
    display: block;
    overflow-y: auto;
    table-layout: fixed;
    max-height: 440px;
  }
  .grid-table-body {
    td {
      height: 80%;
    }
  }
  .grid-table-header {
    th {
      height: 40px;
    }
  }
}